import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';
import '../profile/profile.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import img from '../../image/lion.jpeg';
import img1 from '../../image/account_circle_24dp_FILL0_wght400_GRAD0_opsz24 1.png'
import img2 from '../../image/school_24dp_FILL0_wght400_GRAD0_opsz24 1.png'
import img3 from '../../image/trophy_24dp_FILL0_wght400_GRAD0_opsz24 1.png'
import pimg from '../../image/pimg1.png';
import pvid from '../../image/pvid.png';
import mask from '../../image/footer-logo-2 2.svg'
import profile_logo from '../../image/Vector.png'
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function UserProfile() {
  const location = useLocation();
  const [filter, setFilter] = useState('images');
  const [images, setImages] = useState([]);
  const [videos, setvideos] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showFullImage, setShowFullImage] = useState(false);
  const [fullImageSrc, setFullImageSrc] = useState('');
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [youtubeVideos, setYoutubeVideos] = useState([]);
  const [Name, setName] = useState('');
  const [followersCount, setFollowersCount] = useState(0);
  const [followingCount, setFollowingCount] = useState(0);
  const [followersList, setFollowersList] = useState([]);
  const [followingList, setFollowingList] = useState([]);
  const [showFollowers, setShowFollowers] = useState(false);
  const [showFollowing, setShowFollowing] = useState(false);
  const [uniqueId, setUniqueId] = useState('');
  const [isFollowing, setIsFollowing] = useState(false);
  const [image, setImage] = useState('');
  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const [isMobileNavbarOpen, setMobileNavbarOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const userName = localStorage.getItem('name');

  const [profileImage, setProfileImage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [aboutUs, setAboutUs] = useState('');
  const [college, setCollege] = useState('');
  const [achievements, setAchievements] = useState('');
  const [role, setRole] = useState('');
  const [profileImageURL, setProfileImageURL] = useState(null);
  const [coverImageURL, setCoverImageURL] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [profileImage1, setProfileImage1] = useState(null);
  const [coverImage1, setCoverImage1] = useState(null);
  const [loading, setLoading] = useState(false);
  const [username,setUsername]=useState('')
 

  const unique_id1 = localStorage.getItem('uniqueId');
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchResultsSidebarOpen, setSearchResultsSidebarOpen] = useState(false);



  // Force re-render on query parameter change
  const previousNameRef = useRef();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const currentName = searchParams.get('name');

    if (previousNameRef.current && previousNameRef.current !== currentName) {
      window.location.reload();
    }

    previousNameRef.current = currentName;
  }, [location]);

  const toggleSearchResultsSidebar = () => {
    setSearchResultsSidebarOpen(!isSearchResultsSidebarOpen);
  };
  useEffect(() => {
    // Disable right-click context menu
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    // Disable common keyboard shortcuts for opening developer tools
    const handleKeyDown = (e) => {
      if (
        e.key === 'F12' ||
        (e.ctrlKey && e.shiftKey && (e.key === 'I' || e.key === 'J' || e.key === 'C' || e.key === 'U')) ||
        (e.ctrlKey && e.key === 'U')
      ) {
        e.preventDefault();
      }
    };

    // Detect if developer tools are open and take action
    const handleDevToolsOpen = () => {
      const element = new Image();
      Object.defineProperty(element, 'id', {
        get: function () {
          alert('Developer tools are open!');
          window.location.replace('about:blank'); // Redirect to a blank page
        },
      });
      console.log(element);
    };

    // Add event listeners
    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('keydown', handleKeyDown);
    handleDevToolsOpen();

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query.trim()) {
      fetch('  https://backend-deploy-2.vercel.app/search_bar/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ search_text: query })
      })
        .then(response => response.json())
        .then(data => {

          setSearchResults(data.Names);
          setSearchResultsSidebarOpen(data.Names.length > 0); // Assuming the API directly returns the list of names

        })
        .catch(error => console.error('Error fetching search results:', error));
    } else {
      setSearchResults([]);
      setSearchResultsSidebarOpen(false); // Clear results if the query is cleared
    }
  };


  useEffect(() => {
    if (filter === 'images') {
      fetchImages();
    }
    if (filter === 'videos') {
      fetchVideos();
    }
    if (filter === 'youtube') {
      fetchYoutube();
    }

  }, [filter]);

  useEffect(() => {
    const authStatus = localStorage.getItem('isAuthenticated');
    setIsAuthenticated(authStatus === 'true');
    if (authStatus != 'true') {
      navigate('/')
    }

  }, []);


  const fetchuserdetails = async (fetchedUniqueId) => {
    axios.get(`  https://backend-deploy-2.vercel.app/get_user_details/${fetchedUniqueId}`)
      .then(response => {

        const { profile_image, cover_image, about_you, clg, acheivements, role } = response.data.result[0];
        setProfileImage1(profile_image);
        setCoverImage1(cover_image);
        setAboutUs(about_you);
        setCollege(clg);
        setAchievements(acheivements);
        setRole(role);
        fetch(`${profile_image}`)
          .then(response => response.blob())
          .then(blob => {
            const filename = profile_image.split('/')[-1]
            const file = new File([blob], filename, { type: blob.type });
            const objectURL = URL.createObjectURL(file);
            setProfileImage(file)
            setProfileImageURL(objectURL)
          });

        fetch(`${cover_image}`)
          .then(response => response.blob())
          .then(blob => {
            const filename = cover_image.split('/')[-1]
            const file = new File([blob], filename, { type: blob.type });
            const objectURL = URL.createObjectURL(file);

            setCoverImage(file)
            setCoverImageURL(objectURL)
          });
      })
      .catch(error => {
        alert('Error fetching the profile data:', error);
      });
  }



  const toggleSidebar = () => {
    setMobileSidebarOpen(!isMobileSidebarOpen);
    if (!isMobileSidebarOpen) {
      setMobileNavbarOpen(false); // Close navbar if sidebar is opened
    }
  };

  const toggleNavbar = () => {
    setMobileNavbarOpen(!isMobileNavbarOpen);
    if (!isMobileNavbarOpen) {
      setMobileSidebarOpen(false); // Close sidebar if navbar is opened
    }
  };


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const nameFromUrl = urlParams.get('name');
      setUsername(nameFromUrl);
  }, [window.location.search]);

  useEffect(()=>{
    fetchUniqueId(username);
  },[username])


  const fetchUniqueId = async (nameFromUrl) => {
    try {
      
      const response = await axios.get(`  https://backend-deploy-2.vercel.app/get_unique_id/${nameFromUrl}`);
     
      if (response) {
        const fetchedUniqueId = response.data.unique_id;
        const fetchName1 = response.data.Name;

        setUniqueId(fetchedUniqueId);
        setName(fetchName1)
        fetchName(fetchedUniqueId);
        fetchImage(fetchedUniqueId);
        checkFollowStatus(fetchedUniqueId);
        fetchuserdetails(fetchedUniqueId)
        follwers_count_int()
        following_count_int()



      }
    } catch (error) {
      console.error('Error fetching unique_id:', error);
    }
  };


  const fetchImage = async (fetchedUniqueId) => {
    try {
      const response = await axios.get(`  https://backend-deploy-2.vercel.app/get_profile_image/${fetchedUniqueId}`);

      if (response) {
        const fetchImage1 = response.data.result[0].image_path;

        setImage(fetchImage1)
      }
    }
    catch (error) {
      console.error('Error fetching image:', error);
    }
  };

  const fetchName = async (fetchedUniqueId) => {
    try {
      const response = await axios.get(`  https://backend-deploy-2.vercel.app/get_id/${fetchedUniqueId}`);
      if (response) {
        const fetchName1 = response.data.Name;

        setName(fetchName1)
      }
    } catch (error) {
      console.error('Error fetching unique_id:', error);
    }
  };
  useEffect(()=>{
    fetchImages();
    fetchVideos();
    follwers_count_int();
    following_count_int();
  },[uniqueId])

  const fetchImages = async () => {
    try {
      const response = await axios.get(`  https://backend-deploy-2.vercel.app/images/${uniqueId}`);

      setImages(response.data.image_paths);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };
  const fetchVideos = async () => {
    try {
      const response = await axios.get(`  https://backend-deploy-2.vercel.app/videos/${uniqueId}`);

      setvideos(response.data.videos)
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const fetchYoutube = async () => {
    try {
      const response = await axios.get(`  https://backend-deploy-2.vercel.app/get_youtube_video/${uniqueId}`);

      setYoutubeVideos(response.data.result)
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const follwers_count_int = () => {
    axios.get(`  https://backend-deploy-2.vercel.app/followers_count/${uniqueId}`)
    .then(response => {
      setFollowersCount(response.data.followers_count);
    })
    .catch(error => console.error('Error fetching followers count:', error));
  }

  const following_count_int = () => {
    axios.get(`  https://backend-deploy-2.vercel.app/following_count/${uniqueId}`)
    .then(response => {
      setFollowingCount(response.data.following_count);
    })
    .catch(error => console.error('Error fetching following count:', error));
  }

  useEffect(() => {
    follwers_count_int()
    following_count_int()

  }, [isFollowing]);


  const fetchFollowersList = async () => {
    try {
      const response = await axios.get(`  https://backend-deploy-2.vercel.app/followers/${uniqueId}`);
      setFollowersList(response.data.followers);

    } catch (error) {
      console.error('Error fetching followers list:', error);
    }
  };

  const fetchFollowingList = async () => {
    try {
      const response = await axios.get(`  https://backend-deploy-2.vercel.app/following/${uniqueId}`);
      setFollowingList(response.data.following);


    } catch (error) {
      console.error('Error fetching following list:', error);
    }
  };


  const handleShowFollowers = () => {
    fetchFollowersList();
    setShowFollowers(true);
  };

  const handleShowFollowing = () => {
    fetchFollowingList();
    setShowFollowing(true);
  };

  const handleCloseModal = () => {
    setShowFollowers(false);
    setShowFollowing(false);
  };




  const handleFollowToggle = async () => {
    if (loading) return;
    setLoading(true);
    const newFollowStatus = !isFollowing;


    if (unique_id1 && uniqueId) {
      try {
        const response = await fetch(`  https://backend-deploy-2.vercel.app/follow`, {
          method: newFollowStatus ? 'POST' : 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: uniqueId, follower_id: unique_id1 }),
        });

        if (response.ok) {
          setIsFollowing(newFollowStatus);
        } else {
          console.error('Failed to update follow status');
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    }
    else {
      setLoading(false);
    }
  };

  const checkFollowStatus = async (fetchedUniqueId) => {
    try {
      const response = await axios.get(`  https://backend-deploy-2.vercel.app/check_follow/${unique_id1}/${fetchedUniqueId}`);
      if (response.status === 200) {
        setIsFollowing(response.data.isFollowing);
      }
    } catch (error) {
      console.error('Error fetching follow status:', error);
    }
  };

  const handleImageChange = (e, setImage, setImageURL) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setImageURL(URL.createObjectURL(file));
    }
  };

  const navigate = useNavigate();

  const handleSignOut = () => {
    // Clear local storage
    localStorage.clear();

    // Redirect to the homepage or login page
    navigate('/');
  };




  const showImage = (src) => {
    setFullImageSrc(src);
    setShowFullImage(true);
  };



  const closeImage = () => {
    setShowFullImage(false);
  };


  return (
    <div className='profile_sid123'>
      <div className="profile-page">
        <div className="navbar">
          <div className="navbar-logo1">
            <img src={mask}></img>
            <Link style={{ textDecoration: "none", color: "#D32F2F" }} to="/">CLICK TALKS </Link></div>
          <div className='searching'>
            {isAuthenticated && (
              <div className="search-bar">
              <FontAwesomeIcon icon={faSearch} className="search-icon" />
              <input
                type="text"
                placeholder="Search"
                className="search-input"
                value={searchQuery}
                onChange={handleSearch}
              />
            </div>)}
          </div>
          <div className="navbar-links">

            <Link to="/timeline" className={location.pathname === '/timeline' ? 'active' : ''}>Timeline</Link>


            <Link to="/Contest" className={location.pathname === '/Contest' ? 'active' : ''}>Contest</Link>
            <Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>About Us</Link>

            {isAuthenticated && userName ? (
              <>
                <Link to="/myprofile" className={location.pathname === '/myprofile' ? 'active' : ''}>{userName}</Link>
                <Link to="/" onClick={handleSignOut}>Sign Out</Link>
              </>
            ) : (
              <Link to="/register" className={location.pathname === '/register' ? 'active' : ''}>Register</Link>
            )}



          </div>
          <div className="navbar-menu" onClick={toggleNavbar}>
            &#9776; {/* Three dots icon for navbar */}
          </div>
          {isMobileNavbarOpen && (
            <div className="mobile-navbar-links">
              <Link to='/'>Home</Link>
              <Link to="/timeline" className={location.pathname === '/timeline' ? 'active' : ''}>Timeline</Link>


              <Link to="/Contest" className={location.pathname === '/Contest' ? 'active' : ''}>Contest</Link>
              <Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>About Us</Link>

              {isAuthenticated && userName ? (
                <>
                  <Link to="/myprofile" className={location.pathname === '/myprofile' ? 'active' : ''}>{userName}</Link>
                  <Link to="/" onClick={handleSignOut}>Sign Out</Link>
                </>
              ) : (
                <Link to="/register" className={location.pathname === '/register' ? 'active' : ''}>Register</Link>
              )}

            </div>
          )}
        </div>

        <div
          className="banner"
          style={{ backgroundImage: `url(${coverImageURL})` }}

        ></div>
        <div className="content">
          <div className="profile-card">


            <img src={`${profileImage1}`} alt="Profile" className="profile-pic" />
            <h2>{Name}</h2>
            <h5>{role}</h5>
            <div className='Line-profile'></div>
            <ul className="about">
              <li>
                <span>{followersCount}</span>
                <button className='but123' onClick={handleShowFollowers}>Followers</button>
              </li>
              <li>
                <span>{followingCount}</span>
                <button className='but123' onClick={handleShowFollowing}>Following</button>
              </li>
            </ul>
            <div className='image_upload_timeline1'>
              <button className="upload-button1" onClick={handleFollowToggle}
                disabled={loading}
                style={{
                  cursor: loading ? 'not-allowed' : 'pointer',
                  pointerEvents: loading ? 'none' : 'auto',
                }}>
                {loading ? 'Loading...' : isFollowing ? 'Unfollow' : 'Follow'}
              </button>
            </div>
            <p><span><img src={img1}></img></span>{aboutUs}</p>
            <p><span><img src={img2}></img></span>{college}</p>
            <p><span><img src={img3}></img></span>Achievements</p>
            <ul>
              <li>{achievements}</li>
            </ul>
          </div>
          <div className="posts-section">
            <div className="buttons">
              <div style={{ fontWeight: '600' }}>Posts</div>
              <div className='buttons-2'>
                <div className='p-img-btn' onClick={() => setFilter('images')}>
                  <span><img src={pimg}></img></span>
                  <button

                    className={`filter-button ${filter === 'images' ? 'active' : ''}`}
                    style={{ fontWeight: '600' }}
                  >
                    Images
                  </button>
                </div>
                <div className='p-img-btn' onClick={() => setFilter('videos')}>
                  <span><img src={pvid}></img></span>
                  <button
                    className={`filter-button ${filter === 'videos' ? 'active' : ''}`}
                    style={{ fontWeight: '600' }}
                  >
                    Videos
                  </button>
                </div>
                {/* <div className='p-img-btn' onClick={() => setFilter('youtube')}>
                  <button
                    className={`filter-button ${filter === 'youtube' ? 'active' : ''}`}
                    style={{ fontWeight: '600' }}
                  >
                    YouTube Videos
                  </button>
                </div> */}
              </div>
            </div>
            <div className="posts">
              {filter === 'images' && images.length > 0 && images.map((src, i) => (
                <div key={i} className="post1" >
                  <img src={`${src.image_paths}`} alt={src.image_paths} className='post123' onClick={() => showImage(src.image_paths)} />

                  <div className="likes" style={{display:'block'}}> 
                    <div>
                      <span>Likes : </span>
                      <span>{src.likes}</span>

                    </div>
                    {/* <div style={{
                      width: '24px'
                    }}><ShareButton url={src.image_paths} title={Name} />
                    </div> */}
                  </div>
                </div>
              ))}
              {filter === 'videos' && videos.length > 0 && videos.map((src, i) => (
                <div key={i} className="post1" >
                  <iframe src={src.video_path} frameborder="0" allow="autoplay; encrypted-media" allowfullscreen className="post-video"></iframe>

                  <div className="likes">
                    <span>541</span>
                  </div>

                </div>
              ))}
              {filter === 'youtube' && youtubeVideos.length > 0 && youtubeVideos.map((video, i) => (
                <div key={i} className="post1">
                  <iframe
                    width="100%"
                    height="200"
                    src={video.youtube_path}
                    title={`YouTube video ${i}`}

                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>

                  <div className="likes">
                    <span>541</span>
                  </div>

                </div>
              ))}
            </div>
          </div>
        </div>
        {showFullImage && (
          <div className="full-image-modal">
            <img src={`${fullImageSrc}`} alt="Full View" />
            <button className="close-button" onClick={closeImage}>×</button>
          </div>
        )}
      </div>
      {showFollowers && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>&times;</span>
            <h2>Followers</h2>
            <ul>
              {followersList.map((follower, index) => (
                <li key={index}>{follower.name}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
      {isSearchResultsSidebarOpen && (
        <div className="search-results-sidebar">
          <button onClick={toggleSearchResultsSidebar}>Close</button>
          {searchResults.map(result => (
            <div className="search-result">
              <Link to={`/userprofile?name=${result}`}>
                <img src={profile_logo} alt="Profile Logo" className="profile-logo" />
                <span className="profile-name">{result}</span>
              </Link>
            </div>
          ))}
        </div>
      )}
      

      {showFollowing && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>&times;</span>
            <h2>Following</h2>
            <ul>
              {followingList.map((following, index) => (
                <li key={index}>{following.name}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserProfile;

