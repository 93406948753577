import React, { useState } from 'react';
import './model.css';
import image_logo from '../../image/photo-gallery 1.svg';
import video_logo from '../../image/video-calling 1.svg';
import axios from 'axios';
import imageCompression from 'browser-image-compression';

const Modal = ({ show, handleClose, sr }) => {
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [fileType, setFileType] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [videoLink, setVideoLink] = useState('');
    const [videoUploadProgress, setVideoUploadProgress] = useState(0);

    const userName = localStorage.getItem('name');
    const unique_id = localStorage.getItem('uniqueId');
    const FILE_SIZE_LIMIT = 4.5 * 1024 * 1024;

    const handleFileChange = async (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            if (selectedFile.type.startsWith('image/')) {
                const options = {
                    maxSizeMB: 4.5,
                    useWebWorker: true,
                    maxWidthOrHeight: 1920,
                };
                try {
                    const compressedFile = await imageCompression(selectedFile, options);
                    if (compressedFile.size > FILE_SIZE_LIMIT) {
                        alert('Unable to compress image below 4.5 MB. Please select a smaller image.');
                        event.target.value = null;
                        setFile(null);
                        setFileName('');
                    } else {
                        setFile(compressedFile);
                        setFileName(compressedFile.name);
                        setFileType('image');
                    }
                } catch (error) {
                    console.error('Error compressing the image:', error);
                    alert('Error compressing the image. Please try again.');
                }
            }
        }
    };

    const getEmbedUrl = (url) => {
        if (url.includes('/embed/')) {
            return url;  // Return as is if it's already embedded
        }
        let videoId = '';
        const urlObj = new URL(url);
        
        // If it's a short YouTube link like 'https://youtu.be/3V-dmbxWcz4'
        if (urlObj.hostname === 'youtu.be') {
            videoId = urlObj.pathname.slice(1); // Extract the video ID from the path
        }
    
        // If it's a regular YouTube link like 'https://www.youtube.com/watch?v=3V-dmbxWcz4'
        if (urlObj.hostname.includes('youtube.com') && urlObj.searchParams.get('v')) {
            videoId = urlObj.searchParams.get('v');
        }
    
        // Return the embeddable URL
        return `https://www.youtube.com/embed/${videoId}`;
    };
    

    const handleUpload = () => {
        setUploading(true);
        if (fileType === 'image' && file) {
            const formData = new FormData();
            formData.append('file', file);
            const apiEndpoint = ` https://backend-deploy-2.vercel.app/upload_image/${unique_id}/${userName}`;

            const options = {
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentCompleted < 99 ? percentCompleted : 99);
                },
            };

            axios.post(apiEndpoint, formData, options)
                .then(response => {
                    setUploadProgress(100);
                    alert('Upload successful:', response.data);
                    sr(prev => !prev);
                    handleClose();
                    resetState();
                })
                .catch(error => {
                    console.error('Error during upload:', error);
                    alert('Upload failed:', error.message);
                    resetState();
                });
        } else if (fileType === 'video' && videoLink) {
            const embedUrl = getEmbedUrl(videoLink);
            const videoLink1=embedUrl
            console.log(embedUrl,'srrrr',videoLink)
            const apiEndpoint = ` https://backend-deploy-2.vercel.app/upload_video/${unique_id}/${userName}`;
            
            // Mocking progress to 99% before success
            setVideoUploadProgress(99); 
            
            axios.post(apiEndpoint, { videoLink1 })
                .then(response => {
                    setVideoUploadProgress(100);  // Final 100% after successful response
                    alert('Video link uploaded successfully:', response.data);
                    sr(prev => !prev);
                    handleClose();
                    resetState();
                })
                .catch(error => {
                    console.error('Error during video link upload:', error);
                    alert('Upload failed:', error.message);
                    resetState();
                });
        }
    };

    const resetState = () => {
        setUploadProgress(0);
        setVideoUploadProgress(0);
        setFile(null);
        setFileName('');
        setUploading(false);
        setVideoLink('');
    };

    return (
        <div className='sid_modal'>
            <div className={`modal ${show ? 'show' : ''}`}>
                <div className="modal-content">
                    <span className="close-button" onClick={handleClose}>&times;</span>
                    <h3>Upload</h3>
                    <p>Select the type of file or link you want to upload:</p>
                    <div className="modal-options">
                        <input
                            type="file"
                            id="fileInput"
                            style={{ display: 'none' }}
                            accept="image/*"
                            onChange={handleFileChange}
                            disabled={uploading || fileType === 'video'}
                        />
                        <button className="option-button" onClick={() => {
                            setFileType('image');
                            document.getElementById('fileInput').click();
                        }} disabled={uploading}>
                            <img src={image_logo} alt="Images" />
                            Images
                        </button>
                        <button className="option-button" onClick={() => setFileType('video')} disabled={uploading}>
                            <img src={video_logo} alt="Videos" />
                            YouTube Video
                        </button>
                    </div>
                    {fileType === 'video' && (
                        <div>
                            <input
                                type="text"
                                placeholder="Paste YouTube link here"
                                value={videoLink}
                                onChange={(e) => setVideoLink(e.target.value)}
                                disabled={uploading}
                            />
                             {/* <div className="iframe-demo">
                                <p>pls paste the highlighted url form your link:</p>
                                <pre>
                                    <code>
                                        {'<iframe width="560" height="315" src="'}
                                        <span className="highlight">https://www.youtube.com/embed/demo_video</span>
                                        {'" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>'}
                                    </code>
                                </pre>
                            </div> */}
                        </div>
                        
                    )}
                    {fileName && <p>Filename: {fileName}</p>}
                    {uploading && fileType === 'image' && (
                        <div className="progress-bar">
                            <div className="progress" style={{ width: `${uploadProgress}%` }}>
                                {uploadProgress}%
                            </div>
                        </div>
                    )}
                    {uploading && fileType === 'video' && (
                        <div className="progress-bar">
                            <div className="progress" style={{ width: `${videoUploadProgress}%` }}>
                                {videoUploadProgress}%
                            </div>
                        </div>
                    )}
                    <button className="upload-button1" onClick={handleUpload} disabled={(!file && !videoLink) || uploading}>
                        {uploading ? 'Uploading...' : 'Upload'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
