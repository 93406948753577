import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import back_img from '../../images/Studio photographer-cuate 1 (1).png';
import logo1 from '../../images/footer-logo-2 3.png'
import { useNavigate } from 'react-router-dom';
import { Link ,useLocation} from 'react-router-dom';
import Forgot from './Forgot_password'
import '../register/register.css'
import benefitsImage from '../../image/Group 113.png';
import mask from '../../image/footer-logo-2 2.svg'

function LoginForm() {
    const location = useLocation();
    const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);
    const [isSearchResultsSidebarOpen, setSearchResultsSidebarOpen] = useState(false);
    const [isMobileNavbarOpen, setMobileNavbarOpen] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        // Disable right-click context menu
        const handleContextMenu = (e) => {
          e.preventDefault();
        };
    
        // Disable common keyboard shortcuts for opening developer tools
        const handleKeyDown = (e) => {
          if (
            e.key === 'F12' ||
            (e.ctrlKey && e.shiftKey && (e.key === 'I' || e.key === 'J' || e.key === 'C' || e.key === 'U')) ||
            (e.ctrlKey && e.key === 'U')
          ) {
            e.preventDefault();
          }
        };
    
        // Detect if developer tools are open and take action
        const handleDevToolsOpen = () => {
          const element = new Image();
          Object.defineProperty(element, 'id', {
            get: function () {
              alert('Developer tools are open!');
              window.location.replace('about:blank'); // Redirect to a blank page
            },
          });
          console.log(element);
        };
    
        // Add event listeners
        document.addEventListener('contextmenu', handleContextMenu);
        document.addEventListener('keydown', handleKeyDown);
        handleDevToolsOpen();
    
        // Cleanup event listeners on component unmount
        return () => {
          document.removeEventListener('contextmenu', handleContextMenu);
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, []);

    const toggleSidebar = () => {
        setMobileSidebarOpen(!isMobileSidebarOpen);
        if (!isMobileSidebarOpen) {
            setMobileNavbarOpen(false); // Close navbar if sidebar is opened
        }
    };

    const toggleNavbar = () => {
        setMobileNavbarOpen(!isMobileNavbarOpen);
        if (!isMobileNavbarOpen) {
            setMobileSidebarOpen(false); // Close sidebar if navbar is opened
        }
    };
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [passwordShown, setPasswordShown] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordShown(!passwordShown);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {

            const response = await fetch('  https://backend-deploy-2.vercel.app/verify_registration/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            console.log(response, 'srrr')

            if (response.ok === false) {
                console.error('login failed')
                alert('Login failed, pls check password and username');
            }
            else {
                const responseData = await response.json();
                localStorage.setItem('isAuthenticated', 'true');
                localStorage.setItem('name', responseData.name);
                localStorage.setItem('uniqueId', responseData.unique_id);
                navigate('/myprofile');
            }

            // Here you can handle the response accordingly, such as redirecting to a new page
        } catch (error) {
            console.error('Error logging in:', error.message);
            // Handle error (show error message, etc.)
        }
    };

    return (
        <div className='sid-register'>
            <div className='full-body'>
                <div className="navbar">

                <div className="navbar-logo1">
            <img src={mask}></img>
            <Link style={{ textDecoration: "none", color: "#D32F2F" }} to="/">CLICK TALKS </Link></div>                    <div className="navbar-links">

                 

      <Link to="/Contest" className={location.pathname === '/Contest' ? 'active' : ''}>Contest</Link>
      <Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>About Us</Link>

     
        <Link to="/register" className={location.pathname === '/register' ? 'active' : ''}>Register</Link>
      
                    </div>
                    <div className="navbar-menu" onClick={toggleNavbar}>
                        &#9776; {/* Three dots icon for navbar */}
                    </div>
                    {isMobileNavbarOpen && (
                        <div className="mobile-navbar-links">


<Link to='/'>Home</Link>
      <Link to="/Contest" className={location.pathname === '/Contest' ? 'active' : ''}>Contest</Link>
      <Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>About Us</Link>

     
        <Link to="/register" className={location.pathname === '/register' ? 'active' : ''}>Register</Link>
     
                        </div>
                    )}
                </div>
                <div className="container">
                    <div className="form-container">
                        <h2>Login Form</h2>
                        <p>Kindly fill in your details to Login</p>
                        <form onSubmit={handleSubmit}>
                            <label htmlFor="email">Email address*</label>
                            <input type="email" id="email" name="email" placeholder="Enter email address" required
                                value={formData.email}
                                onChange={handleChange}
                            />
                            <label htmlFor="password">Enter Password</label>
                            <div className="password-container">
                                <input
                                    type={passwordShown ? 'text' : 'password'}
                                    id="password"
                                    name="password"
                                    placeholder="Enter Password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    required
                                />
                                <FontAwesomeIcon
                                    icon={passwordShown ? faEye : faEyeSlash}
                                    onClick={togglePasswordVisibility}
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>

                            <button type="submit">Login</button>
                        </form>
                        <p>
                            <a href="#!" style={{ color: 'black', fontWeight: '600', textDecoration: 'none' }} onClick={() => setModalOpen(true)}>Forgot Password?</a>
                        </p>
                    </div>
                    <div className="benefits-container">
                        <img src={benefitsImage} alt="Membership Benefits" className="benefits-image" />
                    </div>
                </div>
            </div>

            <Forgot isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
        </div>
    );
}

export default LoginForm;
